<template>
  <content-with-sidebar class="blog-wrapper">

    <b-overlay
        :show="isFetchingPlaylists"
        rounded="sm"
    >
      <!-- blogs -->
      <b-row class="blog-list-wrapper">
        <b-card no-body class="col-md-12">
          <b-card-header class="pb-50">
            <h5>
              Поиск
            </h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                  cols="6"
                  md="6"
                  class="mb-md-0 mb-2"
              >
                <label
                    for="search-query"
                    class="text-nowrap mr-50"
                >Яндекс Запрос:</label>
                <b-form-input
                    id="search-query"
                    v-model="yandexSearchQuery"
                    placeholder="Русский Рок"
                    @keyup.enter="fetchPlaylistsList('yandex', true)"
                />
              </b-col>
              <b-col
                  cols="6"
                  md="6"
                  class="mb-md-0 mb-2"
              >
                <label
                    for="search-query"
                    class="text-nowrap mr-50"
                >Spotify Запрос:</label>
                <b-form-input
                    id="search-query"
                    v-model="spotifySearchQuery"
                    placeholder="Нерусский Рок"
                    @keyup.enter="fetchPlaylistsList('spotify', true)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="6"
                  md="6"
                  class="mb-md-0 mb-2"
              >
                <label
                    for="search-query"
                    class="text-nowrap mr-50"
                >Яндекс Ссылка:</label>
                <b-form-input
                    id="search-query"
                    v-model="yandexPlaylistLink"
                    placeholder="https://..."
                    @keyup.enter="fetchPlaylistsList('yandex', true)"
                />
              </b-col>
              <b-col
                  cols="6"
                  md="6"
                  class="mb-md-0 mb-2"
              >
                <label
                    for="search-query"
                    class="text-nowrap mr-50"
                >Spotify Ссылка:</label>
                <b-form-input
                    id="search-query"
                    v-model="spotifyPlaylistLink"
                    placeholder="https://..."
                    @keyup.enter="fetchPlaylistsList('spotify', true)"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-table
            ref="refPlaylistsListTable"
            :items="playlistsList"
            responsive
            :fields="tableColumns"
            primary-key="filmId"
            show-empty
            empty-text="No matching records found"
            class="position-relative"
        >

          <!-- Column: Client -->
          <template #cell(playlist)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :text="avatarText(data.item.title)"
                    variant="primary"
                />
              </template>
              <span class="font-weight-bold d-block">
              {{ data.item.title }}
            </span>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <b-button
                  v-if="streaming==='yandex'"
                  :to="{name: 'yandex-playlist-view', params: { id: data.item.id, owner_id: data.item.owner_id, streaming: 'yandex'}}"
                  variant="primary"
                  class="btn-icon"
                  size="sm"
              >
                <feather-icon icon="ArrowRightCircleIcon"/>
              </b-button>
              <b-button
                  v-if="streaming==='spotify'"
                  :to="{name: 'spotify-playlist-view', params: { id: data.item.id, streaming: 'spotify' }}"
                  variant="success"
                  class="btn-icon"
                  size="sm"
              >
                <feather-icon icon="ArrowRightCircleIcon"/>
              </b-button>
              <b-button
                  :href="'https://ya.ru'"
                  variant="warning"
                  class="btn-icon ml-1"
                  size="sm"
                  target="_blank"
              >
                <feather-icon icon="CastIcon"/>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-row>
    </b-overlay>
    <b-row>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              v-if="total > 0"
              v-model="page"
              per-page="10"
              align="center"
              :total-rows="total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BButton, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import {avatarText, kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import musicStoreModule from "@/views/db-music/musicStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    ContentWithSidebar,
    vSelect,
  },
  data() {
    return {
      disabled: 0,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    kFormatter,
  },
  setup() {
    const STORE_MODULE_NAME = 'playlist'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, musicStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
    const yandexSearchQuery = ref(null)
    const spotifySearchQuery = ref(null)
    const yandexPlaylistLink = ref(null)
    const spotifyPlaylistLink = ref(null)
    const streaming = ref(null)

    // Table Handlers
    const tableColumns = [
      {key: 'playlist', sortable: false},
      {key: 'author', sortable: false},
      {key: 'tracks_count', sortable: false},
      {key: 'actions'},
    ]

    const playlistsList = ref([])
    const total = ref(0)
    const page = ref(1)
    const isFetchingPlaylists = ref(false)
    const yandexFetchPlaylists = (...args) => store.dispatch('playlist/yandexPlaylistSearch', ...args)
    const spotifyFetchPlaylists = (...args) => store.dispatch('playlist/spotifyPlaylistSearch', ...args)

    const fetchPlaylistsList = (varStreaming, reset) => {
      isFetchingPlaylists.value = true

      if (reset) {
        page.value = 1
      }

      if (varStreaming === 'yandex') {
        streaming.value = 'yandex'
        yandexFetchPlaylists({
          q: yandexSearchQuery.value,
          link: yandexPlaylistLink.value,
          page: page.value
        })
            .then(response => {
              playlistsList.value = response.data.playlists
              total.value = response.data.total
              isFetchingPlaylists.value = false
            })
      } else if (varStreaming === 'spotify') {
        streaming.value = 'spotify'
        spotifyFetchPlaylists({
          q: spotifySearchQuery.value,
          link: spotifyPlaylistLink.value,
          page: page.value
        })
            .then(response => {
              playlistsList.value = response.data.playlists
              total.value = response.data.total
              isFetchingPlaylists.value = false
            })
      }
    }

    watch([page], () => {
      fetchPlaylistsList(false, varStreaming)
    })

    return {
      yandexFetchPlaylists,
      spotifyFetchPlaylists,
      streaming,
      isFetchingPlaylists,
      tableColumns,
      total,
      page,
      yandexSearchQuery,
      spotifySearchQuery,
      yandexPlaylistLink,
      spotifyPlaylistLink,
      playlistsList,
      avatarText,
      fetchPlaylistsList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
